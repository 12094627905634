import React, { FC } from 'react';
import { Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IQuestionTranslationValue, IQuestionValue, QuestionWithTranslation } from 'entities/Questions/questions.model';

interface IMultipleTypeProps {
  question: QuestionWithTranslation;
  className?: string;
  isAnotherLang: boolean;
}

export const MultipleType: FC<IMultipleTypeProps> = ({ className, question, isAnotherLang }) => {
  const [showInput, setShowInput] = React.useState<boolean>(false);

  const getOtherOption = (id: string | undefined) => {
    const otherOption = question?.values.find((value) => value.id === id && value.isOther === true);
    const translatedOtherOption = question.translations?.values?.find((value) => value.questionValueId === otherOption?.id);
    return { isExist: !!otherOption, option: otherOption, translatedOption: translatedOtherOption };
  };

  const otherOption = question?.values.find((value) => value.isOther === true);

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setShowInput(e.target.checked);
  };

  const getValueById = (id: string) => {
    return question.values.find((value) => value.id === id)?.value;
  };

  const sortedTranslationsArray = React.useMemo(() => {
    const arr: IQuestionTranslationValue[] = [];
    question.values.forEach((item) => {
      question.translations?.values?.forEach((el) => {
        if (item.id === el.questionValueId) {
          arr.push(el);
        }
      });
    });
    return arr;
  }, [question.values, question.translations?.values]);

  return (
    <Checkbox.Group className={className}>
      {isAnotherLang
        ? sortedTranslationsArray?.map((value) => {
            if (getOtherOption(value.questionValueId).isExist) {
              return null;
            } else {
              return (
                <Checkbox key={value.questionValueId} value={value.value ? value.value : getValueById(value.questionValueId)}>
                  {value.value ? value.value : getValueById(value.questionValueId)}
                </Checkbox>
              );
            }
          })
        : question.values.map((value: IQuestionValue) => {
            if (value.isOther === true) {
              return null;
            } else {
              return (
                <Checkbox key={value.id} value={value.value}>
                  {value.value}
                </Checkbox>
              );
            }
          })}
      {getOtherOption(otherOption?.id).isExist && (
        <Checkbox
          onChange={onChangeCheckbox}
          value={
            isAnotherLang && getOtherOption(otherOption?.id).translatedOption?.value
              ? getOtherOption(otherOption?.id).translatedOption?.value
              : otherOption?.value
          }
        >
          {isAnotherLang && getOtherOption(otherOption?.id).translatedOption?.value
            ? getOtherOption(otherOption?.id).translatedOption?.value
            : otherOption?.value}
        </Checkbox>
      )}
      {showInput && <Input className="questions__form_common-period-input" placeholder="Please specify" />}
    </Checkbox.Group>
  );
};
